import './App.css';
import Signup from './Signup';

function App() {
  return (
   <Signup/>
  );
}

export default App;
