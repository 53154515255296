import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GoogleSpreadsheet } from "google-spreadsheet";
import logo from './Logo.PNG';
import './Signup.css';

const creds = require('./experiment-319715-57a3d985eb6e.json');
const doc = new GoogleSpreadsheet('1vssK-EvSI7oiqEPiJMCQcIkvjguyj0keY4vw3DK3laY');

const Signup = () => {
    const [name, changeName] = useState("");
    const [studentID, changeID] = useState("");
    const [email, changeEmail] = useState("");
    const [university, changeUniversity] = useState("");
    const [pin, changePin] = useState("");
    const [pin2, changePin2] = useState("");
    const [showPinFour, changePinFour] = useState(false);
    const [showPassRequired, changePassReq] = useState(false);
    const [showNameRequired, changeNameReq] = useState(false);
    const [showIDRequired, changeIDReq] = useState(false);
    const [showEmailRequired, changeEmailReq] = useState(false);
    const [showConfirmed, changeConfirmed] = useState(false);
    const [showUniRequired, changeUniReq] = useState(false);

    const [showForm, changeShowForm] = useState(true);

    const appendSpreadsheet = async (row) => {
        try {
            await doc.useServiceAccountAuth(creds);
            await doc.loadInfo();

            const sheet = doc.sheetsByIndex[0];
            const result = await sheet.addRow(row)
        } catch (e) {
            console.error('Error: ', e);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (name.length === 0) {
            changeNameReq(true);
        } 
        if (studentID.length === 0) {
            changeIDReq(true);
        } 
        if (email.length === 0) {
            changeEmailReq(true);
        }
        if (pin !== pin2) {
            changeConfirmed(true);
        }
        if (university.length === 0) {
            changeUniReq(true);
        }
        if (pin.length === 0) {
            changePassReq(true);
        } 
        if (pin.length > 4 || pin.length < 4) {
            changePinFour(true);
        }

        if (!showPinFour && !showUniRequired && !showPassRequired && !showConfirmed && !showEmailRequired && !showNameRequired && !showIDRequired){
            const newRow = { University: university, Name: name, ID: studentID, Email: email, Pin: pin};
            appendSpreadsheet(newRow);
            changeShowForm(false);
        }
    }

    if (showForm) {
    return (
        <div className="Login">
            <h3 className="header">ResKit Sign Up</h3>
            <Form onSubmit={handleSubmit}>

                <Form.Group size="lg" controlId="department">
                <Form.Label className="fieldTitle" style={{paddingBottom: 5}}>University</Form.Label>
                    <Autocomplete
                        id="university"
                        options={universities}
                        getOptionLabel={(option) => option.name}
                        style={{width: 320}}
                        onChange={(event, newValue) => {changeUniversity(newValue.name); changeUniReq(false)}}
                        renderInput={(params) => <TextField {...params} label="Tap to select University" variant="outlined"/>} 
                    />
                    {/* { showUniRequired ? <div><p className="errorText">University is required</p></div> : null }                    */}
                </Form.Group>

                <Form.Group size="lg" controlId="password">
                    <Form.Label className="fieldTitle">First and Last Name</Form.Label>
                    <Form.Control
                        value={name}
                        placeholder={"John Doe"}
                        onChange={(e) => {changeName(e.target.value); changeNameReq(false)}}
                    />
                    {/* { showNameRequired ? <div><p className="errorText">Name is required</p></div> : null } */}
                </Form.Group>

                <Form.Group size="lg" controlId="password">
                    <Form.Label className="fieldTitle">Student ID</Form.Label>
                    <Form.Control
                        value={studentID}
                        placeholder={"xxxxxxxx"}
                        onChange={(e) => {changeID(e.target.value); changeIDReq(false)}}
                    />
                    {/* { showIDRequired ? <div><p className="errorText">Student ID is required</p></div> : null } */}
                </Form.Group>

                <Form.Group size="lg" controlId="password">
                    <Form.Label className="fieldTitle">Email</Form.Label>
                    <Form.Control
                        value={email}
                        placeholder={"email@address.com"}
                        onChange={(e) => {changeEmail(e.target.value); changeEmailReq(false)}}
                    />
                    {/* { showEmailRequired ? <div><p className="errorText">Email is required</p></div> : null } */}
                </Form.Group>

                <Form.Group size="lg" controlId="password">
                    <Form.Label className="fieldTitle">Create a four-digit pin</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder={"xxxx"}
                        value={pin}
                        onChange={(e) => {changePin(e.target.value); changePassReq(false)}}
                    />
                </Form.Group>
                {/* <Form.Group size="lg" controlId="password2">
                    <Form.Label className="fieldTitle">Confirm Pin</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder={"xxxx"}
                        value={pin2}
                        onChange={(e) => {changePin2(e.target.value);}}
                    />
                    { showPassRequired ? <div><p className="errorText">Pin is required</p></div> : null }
                    { showConfirmed ? <div><p className="errorText">Pins must be the same</p></div> : null }
                    { showPinFour ? <div><p className="errorText">Pin must be 4 digits</p></div> : null }
                </Form.Group> */}
                
                <Button block size="sm" variant="secondary" type="submit" style={{marginTop: 10, width: 150}} onClick={() => handleSubmit}>Sign Up</Button>
            </Form>
        </div>
    ) } 
    
    else {
        return (
            <div className="success">
                <img src={logo} height={60} width={60}/>
                <p style={{fontSize: 22}}>Success! You are now registered for your ResKit app.</p>
            </div>
            
        )
    }
}

export default Signup;

const universities = [
    { name: 'Alabama State University' },
    { name: 'Amherst College' },
    { name: 'Angeles College' },
    { name: 'Arkansas State University' },
    { name: 'Auburn University' },
    { name: 'Aurora University' },    
    { name: 'Austin Peay State University' },

    { name: 'Bakersfield College' }, 
    { name: 'Bellarmine University' },  
    { name: 'Belmont University' },
    { name: 'Berea College' },
    { name: 'Berkeley University of California' },
    { name: 'Berry College' },
    { name: 'Birmingham-Southern College' },
    { name: 'Brown University' },
    { name: 'Bryan College' },    
    { name: 'Butte College' },

    { name: 'California Baptist University' },
    { name: 'California Institute of Technology' },
    { name: 'Carnegie Mellon University' },
    { name: 'Carson-Newman University' },
    { name: 'Chapman University' },
    { name: 'Christian Brothers University' }, 
    { name: 'Claremont McKenna College' },
    { name: 'Colorado School of Mines' },  
    { name: 'Colorado State University' },
    { name: 'Columbia University' }, 
    { name: 'Cornell University' },  
    { name: 'Cumberland University' },

    { name: 'Dartmouth College' }, 
    { name: 'Delaware State University' }, 
    { name: 'DePaul University' }, 
    { name: 'DeVry University' },
    { name: 'Dominican University of California' },
    { name: 'Duke University' },

    { name: 'Eastern Kentucky University' },
    { name: 'Elmhurst College' },
    { name: 'Embry-Riddle Aeronautical University' },
    { name: 'Emory University' },

    { name: 'Fisk University' },
    { name: 'Florida Atlantic University' },
    { name: 'Florida Gulf Coast University' },  
    { name: 'Florida Institute of Technology' },
    { name: 'Florida Southern College' },
    { name: 'Florida State University' },
    { name: 'Fullerton College' },

    { name: 'Georgetown University' },
    { name: 'Georgia Institute of Technology' },  
    { name: 'Georgia Southern University' },
    { name: 'Grossmont College' },

    { name: 'Harvard University' },
    { name: 'Harvey Mudd College' }, 
         
    { name: 'Illinois State University' },
    { name: 'Indiana University' },
    { name: 'Indiana State University' },  
    { name: 'Indiana University' },
    { name: 'Iowa State University' },

    { name: 'Jacksonville University' }, 
    { name: 'Johns Hopkins University' },
    { name: 'Johnson University' },

    { name: 'Kansas State University' },
    { name: 'Kaplan University' },
    { name: 'Keiser University' },  
    { name: 'Kentucky State University' },
    { name: 'King University' },

    { name: 'Lee University' },
    { name: 'Loyola University Chicago' },

    { name: 'Massachusetts Institute of Technology' }, 
    { name: 'Milligan College' },

    { name: 'Northwestern University' },
    { name: 'Oglethorpe University' },
        
    { name: 'Princeton Univesity' },
    { name: 'Purdue University' },

    { name: 'Rhodes College' },
    { name: 'Rice University' },
    { name: 'Rollins College' },
        
    { name: 'Stanford University' },
    { name: 'Stetson University' },  
    { name: 'Southern Adventist University' },
    { name: 'Southern Illinois University' },

    { name: 'Tennessee State University' },
    { name: 'Tennessee Technological University' }, 
    { name: 'Tennessee Wesleyan College' },

    { name: 'Union University' },
    { name: 'University of Central Florida' },
    { name: 'University of Chicago' }, 
    { name: 'University of Delaware' }, 
    { name: 'University of Denver' },
    { name: 'University of Georgia' },
    { name: 'University of Illinois' },
    { name: 'Univeristy of Iowa' },
    { name: 'University of Memphis' },
    { name: 'University of Miami' },  
    { name: 'University of Michigan' },
    { name: 'University of New Haven' },  
    { name: 'University of Pennsylvania' }, 
    { name: 'University of Tampa' },  
    { name: 'University of Tennessee' },
          
    { name: 'Vanderbilt University' },
    { name: 'Wesleyan University' }, 
    { name: 'Williams College' },      
    { name: 'Yale University' },
]